<template>
  <!-- BANNER TYPE 5 -->
  <div
    id="banner-type5"
    class="mt-1"
    :class="data.block"
  >
    <swiper
      ref="slider"
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(banner,index) in banners"
        :key="banner._id + index"
      >
        <div>
          <img
            contain
            width="100%"
            class="slider-img rounded"
            :src="baseImgUrl + banner.mobile_image || image"
            @click="clickBanner(banner.mobile_hyperlink, $router)"
          >
        </div>
      </swiper-slide>
      <!-- 上一頁 -->
      <div
        slot="button-prev"
        class="swiper-button-prev"
        @click="slidePrev"
      >
        <v-icon
          medium
          class="white--text"
        >
          mdi-chevron-left
        </v-icon>
      </div>

      <!-- 下一頁 -->
      <div
        slot="button-next"
        class="swiper-button-next"
        @click="sliderNext"
      >
        <v-icon
          medium
          class="white--text"
        >
          mdi-chevron-right
        </v-icon>
      </div>
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { clickBannerEv } from './clickBanner'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    ...mapState({
      bannerDelay: state => state.websiteData.settings.banner_interval,
    }),

    banners() {
      return this.data.data.banner
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },

    swiperOption() {
      return {
        autoplay: {
          delay: +this.bannerDelay * 1000 || 3000,
        },

        loop: true,
        slidesPerView: 1.1,
        spaceBetween: 8,
        centeredSlides: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    },
  },

  methods: {
    sliderNext() {
      this.$refs.slider.$swiper.slideNext()
    },
    slidePrev() {
      this.$refs.slider.$swiper.slidePrev()
    },

    clickBanner(hyperlink, router) {
      clickBannerEv(hyperlink, router)
    },
  },
}
</script>

<style lang="scss" scoped>

.swiper-button-prev,
.swiper-button-next {
	&::after {
		display: none;
	}

	i {
		font-size: 30px;
	}
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 20px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: 20px;
}
</style>
